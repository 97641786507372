/* You can add global styles to this file, and also import other style files */

/* Custom Badge  */

.badge-circle {
    width: 1.7rem;
    /* color: white; */
    border-radius: 1rem;
    padding: 0 !important;
    font-size: 1rem;
  }
  
  .badge-circle-true {
    width: 0.7rem;
    height: 0.7rem;
    border-radius: 1rem;
    padding: 0 !important;
    margin-top: -0.2rem;
    background-color: lightgreen;
  }
  .badge-circle-false {
    width: 0.7rem;
    height: 0.7rem;
    border-radius: 1rem;
    padding: 0 !important;
    margin-top: -0.2rem;
    background-color: gray;
  }
  
  .badge-style {
    color: white;
    padding: 0px 4px;
    border-radius: 4px;
  }
  
  .badge-outline {
    background-color: transparent;
    border: 1px solid rgb(0, 0, 0);
    color: #000;
  }
  
  .badge.badge-lg {
    font-size: 100%;
    font-weight: 500;
  }
  
  .badge.badge-sm {
    line-height: 1.3rem;
    width: 1.3rem;
  }
  
  .badge-outline.badge {
    background-color: transparent;
    border: 1px solid #3273dc;
    color: #000;
  }
  
  .badge-outline.badge-secondary {
      background-color: transparent;
    border: 1px solid rgb(0, 0, 0);
    color: #000;
  }
  
  .badge-outline.badge-success {
      background-color: transparent;
    border: 1px solid #23d160;
    color: #000;
  }
  
  .badge-outline.badge-danger {
      background-color: transparent;
    border: 1px solid #ff3860;
    color: #000;
  }
  
  .badge-outline.badge-warning {
      background-color: transparent;
    border: 1px solid #f57f17;
    color: #000;
  }
  
  .badge-outline.badge-info {
      background-color: transparent;
    border: 1px solid #209cee;
    color: #000;
  }
  
  .badge-outline.badge-light {
      background-color: transparent;
    border: 1px solid rgb(0, 0, 0);
    color: #000;
  }
  
  .badge-outline.badge-dark {
      background-color: transparent;
    border: 1px solid #000;
    color: #000;
  }

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
td {
  border-spacing: 0;
}


.ql-toolbar.ql-snow {
  display: flex;
  flex-wrap: wrap;
}
.ql-toolbar.ql-snow .ql-formats {
  margin-right: 0;
}

.selected-list .c-btn {
  border: none;
  border-bottom: 1px solid rgba(0,0,0,.42);
  box-sizing: border-box;
  border-radius: 0;
}
.selected-list .c-btn .c-list {
  width: calc(100% - 30px);
}
.selected-list .c-list .c-token {
  color: white;
  background-color: #3f51b5;
  border-radius: 5px !important;
}
.pure-checkbox input[type=checkbox]+label {
  display: block;
}

.mat-checkbox .mat-checkbox-layout {
  vertical-align: text-top;
}